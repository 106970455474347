import './css/Location-Item.css';
import './css/adaptive/Location-Item.css';
import { ItemModelLocation } from './ItemModelLocation';

const LocationItem = (props) => {

    const Towns = () => {
        return props.item.towns.map((town, i) => {
            const townName = town.town.replace(/[0-9]/g, '');
            const item = new ItemModelLocation(town.state, town.town, town.code);
            return (
                <tr key={i} onClick={() => {props.onClickItemLocation(item)}}>
                    <td>
                        <div>
                            <h3 id='location-txt-code' className='text'>{town.code}</h3>
                            <h3 id='location-txt-location' className='text'>{townName}</h3>
                        </div>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className='location-item-root'>
            <h3 className='textBold'>{props.item.state}</h3>
            <table>
                <tbody>
                    <Towns />
                </tbody>
            </table>
        </div>
    )
}

export default LocationItem;