import { forwardRef, useEffect, useImperativeHandle } from 'react';
import './css/Countdown.css';
import './css/Countdown-Mobile.css';

export const Countdown = forwardRef((props, ref) => {
    var timer = null;
    var goalsTime = "00:00 AM";
    var isNextDay = false;

    function startCountdown(goals, nextDay) {
        goalsTime = goals;
        isNextDay = nextDay;
        startTimer();
    }

    function startTimer() {
        timer = setInterval(() => {
            count();
        }, 1000);
    }

    function stopTimer() {
        clearInterval(timer);
    }

    function onTimerEnd() {
        props.onTimerEnd();
    }

    function count() {
        const now = Date.now();
        const hour24 = time12To24(goalsTime);
        let [hours, minutes] = hour24.split(':');

        const nextDate = new Date();
        if (isNextDay) {
            const today = new Date();
            nextDate.setDate(today.getDate() + 1);
        }
        nextDate.setHours(hours, minutes, 0);

        var remain = ((nextDate - now) / 1000);
        const hh = pad((remain / 60 / 60) % 60);
        setHour(hh);

        const mm = pad((remain / 60) % 60);
        setMinutes(mm);

        const ss = pad(remain % 60);
        const txtSeconds = findView('seconds').innerText;
        const sec = parseInt(txtSeconds);

        const isEnd = hh == 0 && mm == 0 && ss == 0;
        const missed = sec < 0 || sec < ss;

        if (isEnd || missed) {
            stopTimer();
            onTimerEnd();
        }
        setSeconds(ss);

        const currentDay = new Date().getDate();
        props.onTick(currentDay);

    }

    function pad(num) {
        const numb = "0" + parseInt(num);
        const last = numb.length;
        return numb.substring(last - 2, last);
    }

    useImperativeHandle(ref, () => {
        return { startCountdown, startTimer, stopTimer }
    });

    const time12To24 = (time12) => {
        const [time, modifier] = time12.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    }

    function setHour(value) {
        findView('hours').innerHTML = value;
    }

    function setMinutes(value) {
        findView('minutes').innerHTML = value;
    }

    function setSeconds(value) {
        findView('seconds').innerHTML = value;
    }

    function findView(id) {
        return document.getElementById(id);
    }

    return (
        <div className='countdown-root'>
            <div>
                <h1 id='hours' className='textBold'>00</h1>
                <h4 className='textBold'>JAM</h4>
            </div>
            <div className='countdown-center'>
                <h1 id='minutes' className='textBold'>00</h1>
                <h4 className='textBold'>MINIT</h4>
            </div>
            <div>
                <h1 id='seconds' className='textBold'>00</h1>
                <h4 className='textBold'>SAAT</h4>
            </div>
        </div>
    )
})

export default Countdown;