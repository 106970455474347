import './css/Header.css';
import './css/Header-Mobile.css';
import './css/Header-Land.css';

export const Header = () => {
    return (
        <div className='header-root'>
            <div>
                <img src="../logo.png" />
                <h1 className='textBold'>Waktu Solat Malaysia</h1>
            </div>
            <h2 className='text'>*Waktu Solat hanya bagi Semenanjung Malaysia, Sabah dan Sarawak</h2>
        </div>
    )
}

export default Header;