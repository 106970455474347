export class Storage {
    constructor() {
        this.STORAGE_NAME = 'com.jamsolattv.atista.my.';
        this.KEY_CODE = this.STORAGE_NAME + "code";
        this.KEY_TOWN = this.STORAGE_NAME + "town";
    }

    setCode(value) {
        localStorage.setItem(this.KEY_CODE, value);
    }
    getCode() {
        return localStorage.getItem(this.KEY_CODE);
    }

    setTown(value) {
        localStorage.setItem(this.KEY_TOWN, value);
    }
    getTown() {
        return localStorage.getItem(this.KEY_TOWN);
    }

} 