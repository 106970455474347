import React, { useEffect, useState } from "react";
import './css/Slider.css';
import imageSlide from "./data/data";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const BackgroundSlider = () => {
    const randomIndex = Math.floor(Math.random() * 12);
    //const randomIndex = 0;

    const sliderDuration = 30000;
    const imagesSize = imageSlide.length - 1;

    useEffect(() => {
        const img = document.getElementById("img" + randomIndex);
        img.style.display = "block";

        var index = randomIndex;
        setInterval(function () {
            hide(index);
            index = index === imagesSize ? 0 : index + 1;
            show(index);
        }, sliderDuration);
    });

    function show(id) {
        var img = document.getElementById("img" + id);
        img.classList.add('slideShow');
        img.style.display = "block";
        img.onanimationend = () => {
            img.classList.remove('slideShow');
        }
    }

    function hide(id) {
        var img = document.getElementById("img" + id);
        img.classList.add('slideHide');
        img.onanimationend = () => {
            img.style.display = "none";
            img.classList.remove('slideHide');
        }
    }

    const ImageSlider = () => {
        return imageSlide.map((item, i) => {
            return (
                <div key={i} id={"img" + i} className="slider-parent">
                    <LazyLoadImage
                        className="slider-image"
                        effect="blur"
                        src={item.url}
                    />
                </div>
            )
        });
    }

    return (
        <div className="slider-root">
            <ImageSlider />
        </div>
    )
}

export default BackgroundSlider;