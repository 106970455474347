import { forwardRef, useImperativeHandle } from 'react';
import './css/Footer.css';
import './css/Footer-Mobile.css';
import { ModelPrayerTime } from '../../model/ModelPrayerTime';

export const Footer = forwardRef((props, ref) => {

    const model = new ModelPrayerTime();
    const defaultTime = "00:00 AM";
    const defaultTimePM = "00:00 PM";
    const defaultDate = model.date;
    const arrTimes = [
        "imsak", "subuh", "syuruk", "zohor", "asar", "maghrib", "isyak"
    ];

    function prayerData(value) {
        setText('date', value.date);
        setText('imsak', value.imsak);
        setText('subuh', value.subuh);
        setText('syuruk', value.syuruk);
        setText('zohor', value.zohor);
        setText('asar', value.asar);
        setText('maghrib', value.maghrib);
        setText('isyak', value.isyak);
    }

    useImperativeHandle(ref, () => {
        return { prayerData }
    });

    function onClickDerma() {
        const url = 'https://dana.e-waktusolat.my/';
        window.open(url, '_blank');
    }

    function setText(id, value) {
        findView("footer-" + id).innerHTML = value;
    }

    function findView(id) {
        return document.getElementById(id);
    }

    const Times = () => {
        return arrTimes.map((item, i) => {
            const title = item.toUpperCase();
            const id = "footer-" + item;
            return (
                <div key={i}>
                    <h4 className='textBold'>{title}</h4>
                    <h3 id={id} className='textBold'>{defaultTime}</h3>
                </div>
            )
        })
    }

    return (
        <footer>
            <div className='footer-data'>
                {/* Date */}
                <h5 id='footer-date' className='textSemi'>{defaultDate}</h5>
                <div className='prayer-times'><Times /></div>
                {/* Warning */}
                <h6 id='footer-warning' className='text'>*Waktu Solat hanya bagi Semenanjung Malaysia, Sabah dan Sarawak</h6>
            </div>
            <button
                className='button btn-footer'
                onClick={onClickDerma}>
                DERMA SEIKHLAS HATI
                <img src='./icons/ic_derma.svg' />
            </button>
        </footer>
    )
})

export default Footer;