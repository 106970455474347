import { forwardRef, useImperativeHandle } from 'react';
import './css/Left.css';
import './css/Left-Mobile.css';

export const Left = forwardRef((props, ref) => {
    const defaultTime = "00:00 AM";
    const defaultTimePM = "00:00 PM";
    const arrTime = [
        "imsak", "subuh", "syuruk", "zohor", "asar", "maghrib", "isyak"
    ];

    function prayerData(value) {
        setText('imsak', value.imsak);
        setText('subuh', value.subuh);
        setText('syuruk', value.syuruk);
        setText('zohor', value.zohor);
        setText('asar', value.asar);
        setText('maghrib', value.maghrib);
        setText('isyak', value.isyak);
    }

    useImperativeHandle(ref, () => {
        return { prayerData }
    });

    function setText(id, value) {
        findView("left-" + id).innerHTML = value;
    }

    function findView(id) {
        return document.getElementById(id);
    }

    const Times = () => {
        return arrTime.map((item, i) => {
            const title = item.toUpperCase();
            const id = "left-" + item;
            const last = i == (arrTime.length - 1) ? " isIsyak" : "";
            return (
                <div key={i} className={"left-item" + last}>
                    <div>
                        <h4 className='textBold'>{title}</h4>
                        <h3 id={id} className='textBold'>{defaultTime}</h3>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className='left-root'>
            <Times />
        </div>
    )
})

export default Left;