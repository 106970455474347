const imageSlide = [
    {url: '../background/mosque1.jpg'},
    {url: '../background/mosque2.jpg'},
    {url: '../background/mosque3.jpg'},
    {url: '../background/mosque4.jpg'},
    {url: '../background/mosque5.jpg'},
    {url: '../background/mosque6.png'},
    {url: '../background/mosque7.png'},
    {url: '../background/mosque8.jpg'},
    {url: '../background/mosque9.jpg'},
    {url: '../background/mosque10.jpg'},
    {url: '../background/mosque11.jpg'},
    {url: '../background/mosque12.jpg'}
]

export default imageSlide;