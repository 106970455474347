import React, { Component, forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import LocationItem from './item/Location-Item';
import './css/Locations.css';
import './css/adaptive/Locations.css';
import { getLocations } from '../../api/api';
import { LocationPlaceHolder } from './placeholder/Locations-Placeholder';
import { ItemModelLocation } from './item/ItemModelLocation';
import { ModelPrayerTime } from '../../model/ModelPrayerTime';

export const Locations = forwardRef((props, ref) => {
    const [locations, setLocations] = useState([]);
    const [locationsSearch, setLocationsSearch] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searching, setSearching] = useState(false);
    var backdropView = null;
    var cardView = null;

    function showDialog() {
        showBackdrop();
        backdropView.addEventListener('mousedown', event => {
            if (event.target.id == backdropView.id) {
                hideDialog();
            }
        })
        showCard();

        const btnClose = document.getElementById("btn-close");
        btnClose.onclick = () => {
            hideDialog();
        }

        if (!loaded) {
            setTimeout(() => {
                requestGetLocations();
            }, 1000);
        }
    }

    useImperativeHandle(ref, () => {
        return { showDialog }
    });

    useEffect(() => {
        backdropView = document.getElementById('dialog-root');
        cardView = document.getElementById('dialog-parent');
        if (loaded) {
            const searchView = document.getElementById('searchView');
            searchView.oninput = () => {
                const query = searchView.value;
                if (query.length > 1) {
                    filter(query);
                    if (!searching) {
                        setSearching(true);
                    }
                } else {
                    if (searching) {
                        setSearching(false);
                    }
                }
            }
        }
        //let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    })

    function requestGetLocations() {
        getLocations().then((result) => {
            setLocations(result);
            locationLoaded();
        });
    }

    function locationLoaded() {
        setLoaded(true);
        setLoading(false);
    }

    function onClickItem(item) {
        hideDialog();
        props.onHide();
        props.onLocationChanged(item);
    }

    const ItemLocation = () => {
        return locations.map((item, i) => {
            return (
                <LocationItem id={"item" + i} key={i} item={item} onClickItemLocation={(v) => onClickItem(v)} />
            )
        })
    }

    const ItemLocationSearch = () => {
        return locationsSearch.map((item, i) => {
            return (
                <LocationItem id={"item" + i} key={i} item={item} onClickItemLocation={(v) => onClickItem(v)} />
            )
        })
    }

    const filter = (query) => {
        const mQuery = query.toLowerCase();
        setLocationsSearch(
            locations.filter(function (item) {
                const getState = item.state;
                const state = getState.toLowerCase();

                const getTowns = item.towns;
                var townSize = 0;
                getTowns.map((v, i) => {
                    const getTown = v.town;
                    const town = getTown.toLowerCase();
                    const found = town.includes(mQuery);
                    if (found) {
                        townSize = townSize + 1;
                    }
                })
                return townSize > 0 || state.includes(mQuery);
            })
        )
    }

    function showBackdrop() {
        const backdrop = backdropView.style;
        backdrop.display = "flex";
        backdrop.animation = "backdropIn 300ms ease-in forwards";
    }

    function hideBackdrop() {
        const backdrop = backdropView.style;
        setTimeout(() => {
            backdrop.animation = "backdropOut 300ms ease-out forwards";
            setTimeout(() => {
                backdrop.display = "none";
                props.onHide();
            }, 300);
        }, 300);
    }

    function showCard() {
        const backdrop = backdropView.style;
        const card = cardView.style;
        card.animation = "cardIn";
        card.animationDuration = "400ms";
        card.animationDelay = "200ms";
        card.top = "100%";
        card.display = "block";
        cardView.onanimationend = () => {
            card.top = "0";
        }
    }

    function hideCard() {
        const card = cardView.style;
        card.animationDelay = "100ms";
        card.animation = "cardOut";
        card.animationDuration = "500ms";
        cardView.onanimationend = () => {
            card.top = "100%";
            card.display = "none";
        }
    }

    function hideDialog() {
        hideBackdrop();
        hideCard();
    }

    return (
        <div id="dialog-root" className='dialog-root'>
            <div id="dialog-parent">
                <div id="dialog-card">
                    <div className='location-header'>
                        <h3 className='textBold'>Tukar Lokasi</h3>
                        <img id='btn-close' src='./icons/ic_close.svg'/>
                    </div>
                    <div className="location-body">
                        {loading ? <LocationPlaceHolder /> :
                            <div id='location-container'>
                                <input id='searchView' className='text'
                                    type="text" name="search"
                                    placeholder="Taip untuk mula carian..." />
                                {searching ? <ItemLocationSearch /> : <ItemLocation />}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
})

export default Locations;