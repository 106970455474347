import { forwardRef, useImperativeHandle, useRef, useState, useCallback, useEffect } from 'react';
import './css/TimeCounter.css';
import './css/TimeCounter-Mobile.css';
import { ModelPrayerTime } from '../../model/ModelPrayerTime';
import { Countdown } from './countdown/Countdown';

export const TimeCounter = forwardRef((props, ref) => {
    const model = useRef(new ModelPrayerTime());
    const arrPrayerTitle = ["IMSAK", "SUBUH", "SYURUK", "ZOHOR", "ASAR", "MAGHRIB", "ISYAK"];
    arrPrayerTitle.reverse();
    var arrPrayerTime = [];
    var arrTimestamp = [];
    var txtDate = null;

    /*const tImsak = "12:09 AM";
    const tSubuh = "12:10 AM";
    const tSyuruk = "12:11 AM";
    const tZohor = "12:12 AM";
    const tAsar = "12:13 AM";
    const tMaghrib = "12:14 AM";
    const tIsyak = "12:15 AM";*/

    const refCountdown = useRef();

    useEffect(() => {
        txtDate = findView('date');
        setText('date', defaultDate());
    })

    function prayerData(value) {
        model.current = value;
        setText('date', model.current.date);
        arrPrayerTime = [];
        arrPrayerTime.push(value.imsak);
        arrPrayerTime.push(value.subuh);
        arrPrayerTime.push(value.syuruk);
        arrPrayerTime.push(value.zohor);
        arrPrayerTime.push(value.asar);
        arrPrayerTime.push(value.maghrib);
        arrPrayerTime.push(value.isyak);

        /*arrPrayerTime.push(tImsak);
        arrPrayerTime.push(tSubuh);
        arrPrayerTime.push(tSyuruk);
        arrPrayerTime.push(tZohor);
        arrPrayerTime.push(tAsar);
        arrPrayerTime.push(tMaghrib);
        arrPrayerTime.push(tIsyak);*/

        arrPrayerTime.reverse();

        checkTimePosition();
        const location = model.current.code + " - " + model.current.location;
        setText('location', location);
    }

    function stopTimer() {
        refCountdown.current?.stopTimer();
    }

    function onClickLocation() {
        props.locationListener();
        stopTimer();
    }

    function onHideDialog() {
        refCountdown.current?.startTimer();
    }

    function onTick(currentDay) {
        if (findCurrentDate() < currentDay) {
            if (window.navigator.onLine) {
                stopTimer();
                props.onNewDay();
            }
        }
    }

    function findCurrentDate() {
        const getTxtDate = txtDate.innerText;
        const getDateOnly = getTxtDate.substring(0, 2);
        return parseInt(getDateOnly);
    }

    function onCountdownEnd() {
        checkTimePosition();
    }

    useImperativeHandle(ref, () => {
        return { prayerData, onHideDialog }
    });

    function checkTimePosition() {
        arrTimestamp = [];
        arrTimestamp.push(timestamp(model.current.imsak));
        arrTimestamp.push(timestamp(model.current.subuh));
        arrTimestamp.push(timestamp(model.current.syuruk));
        arrTimestamp.push(timestamp(model.current.zohor));
        arrTimestamp.push(timestamp(model.current.asar));
        arrTimestamp.push(timestamp(model.current.maghrib));
        arrTimestamp.push(timestamp(model.current.isyak));

        /*arrTimestamp.push(timestamp(tImsak));
        arrTimestamp.push(timestamp(tSubuh));
        arrTimestamp.push(timestamp(tSyuruk));
        arrTimestamp.push(timestamp(tZohor));
        arrTimestamp.push(timestamp(tAsar));
        arrTimestamp.push(timestamp(tMaghrib));
        arrTimestamp.push(timestamp(tIsyak));*/

        arrTimestamp.reverse();

        var idx = 0;
        const now = currentTimestamp();
        const timeSize = arrTimestamp.length - 1;
        for (const [i, e] of arrTimestamp.entries()) {
            idx = i;
            if (e <= now) {
                idx = i - 1;
                break;
            }
        }
        if (idx > -1) {
            const title = arrPrayerTitle[idx];
            setText('timeTitle', title);

            const time = arrPrayerTime[idx];
            const getTimestamp = arrTimestamp[idx];
            setText('timeTime', time);
            refCountdown.current?.startCountdown(time, false);
        } else {
            setText('timeTitle', "IMSAK");

            const time = arrPrayerTime[6];
            const getTimestamp = arrTimestamp[5];
            setText('timeTime', time);
            refCountdown.current?.startCountdown(time, true);
        }
        const isImsak = idx == 6;
        setText('next', isImsak ? "Waktu Seterusnya" : "Waktu Solat Seterusnya");
        setText('next2', isImsak ? "Sehingga Waktu Seterusnya Di Zon" : "Sehingga Waktu Solat Seterusnya Di Zon")
    }

    const time12To24 = (time12) => {
        const [time, modifier] = time12.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    }

    const timestamp = (time) => {
        const hour24 = time12To24(time);
        let [hours, minutes] = hour24.split(':');
        const newDate = new Date();
        newDate.setHours(hours, minutes, 0);
        return newDate.getTime();
    }

    const currentTimestamp = () => {
        return Date.now();
    }

    const setText = (id, value) => {
        findView(id).innerHTML = value;
    }

    const findView = (id) => {
        return document.getElementById("counter-" + id);
    }

    function defaultDate() {
        const date = new Date();
        const dtMasehi = date.toLocaleString('ms-MY', { day: 'numeric', month: 'long', year: 'numeric' });
        return dtMasehi + " Masehi | 00 xxxxxxxx 0000";
    }

    function onClickDerma() {
        const url = 'https://dana.e-waktusolat.my/';
        window.open(url, '_blank');
    }

    return (
        <div className='time-counter'>
            <div className='time-counter-parent'>
                <h2 id='counter-date' className='textBold'></h2>
                <h2 id='counter-next' className='textBold'>Waktu Solat Seterusnya</h2>
                <h2 id='counter-timeTitle' className='textBold'>XXXXXX</h2>
                <h2 id='counter-timeTime' className='textBold'>00:00 AM</h2>

                <Countdown ref={refCountdown}
                    onTick={(v) => onTick(v)}
                    onTimerEnd={onCountdownEnd}
                />

                <h2 id='counter-next2' className='textBold'>Sehingga Waktu Solat Seterusnya Di Zon</h2>
                <h2 id='counter-location' className='textBold'>XXX00 - XXXXXXX XXXXXXX XXXXX</h2>

                <div className='counter-button-container'>
                    <button
                        className='button btn-location'
                        onClick={onClickLocation}>
                        PILIH LOKASI
                        <img src='./icons/ic_location.svg' />
                    </button>

                    <button
                        className='button btn-derma'
                        onClick={onClickDerma}>
                        DERMA SEIKHLAS HATI
                        <img src='./icons/ic_derma.svg' />
                    </button>
                </div>

            </div>
        </div>
    )

})

export default TimeCounter;