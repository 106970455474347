import './css/App.css';
import './css/App-Mobile.css';
import './css/TextStyle.css'
import './css/Buttons.css'
import { React, useEffect, useRef } from 'react';
import { BackgroundSlider, Header, TimeCounter, Footer, Left, Locations } from './ui/pages';
import { ModelPrayerTime } from './model/ModelPrayerTime'
import { getPrayerTime } from './api/api';
import { Storage } from './data/Storage';

const App = () => {

  const childRef = useRef();
  const prayerRef = useRef();
  const prayerLeftRef = useRef();
  const counterRef = useRef();
  const database = new Storage();
  var model = new ModelPrayerTime();
  if (database.getCode() == null) {
    database.setCode("JOH01");
  }
  model.code = database.getCode();

  if (database.getTown() == null) {
    database.setTown("Pulau Aur dan Pulau Pemanggil");
  }
  model.location = database.getTown();

  function onClickLocation() {
    childRef.current?.showDialog();
  }

  function onHideDialog() {
    counterRef.current?.onHideDialog();
  }

  function onChangeLocation(item) {
    database.setCode(item.code)
    database.setTown(item.town);
    model.location = item.town;
    model.code = item.code;
    setTimeout(() => {
      requestGetPrayerTime();
    }, 200);
  }

  function onNewDay() {
    requestGetPrayerTime();
  }

  const arrMonth = [
    "Muharram", "Safar", "RabiulAwal", "RabiulAkhir",
    "JamadilAwal", "JamadilAkhir", "Rejab", "Syaaban",
    "Ramadan", "Syawal", "Zulkaedah", "Zulhijjah"
  ];

  useEffect(() => {
    requestGetPrayerTime();
  })

  function requestGetPrayerTime() {
    getPrayerTime(model.code).then((result) => {
      const hijriDate = result.hijri_date;
      const hijriMonth = result.hijri_month;
      const hijriYear = result.hijri_year;
      model.date = createDate(hijriDate, hijriMonth, hijriYear);
      const times = result.times;
      model.imsak = createTime(times.imsak);
      model.subuh = createTime(times.subuh);
      model.syuruk = createTime(times.syuruq);
      model.zohor = createTime(times.zohor);
      model.asar = createTime(times.asar);
      model.maghrib = createTime(times.maghrib);
      model.isyak = createTime(times.isak);
      prayerRef.current?.prayerData(model);
      prayerLeftRef.current?.prayerData(model);
      counterRef.current?.prayerData(model);
    })
  }

  function createDate(day, month, year) {
    return day + " " + arrMonth[month - 1] + " " + year;
  }

  function createTime(time) {
    const idxSeparator = time.indexOf(":");
    const hour = time.substring(0, idxSeparator);
    const minutes = time.substring(idxSeparator, time.length);

    const number = parseInt(hour);
    const finalHour = number < 10 ? "0" + number : "" + number;
    return finalHour + "" + minutes;
  }

  return (
    <div className='app-parent'>
      <BackgroundSlider />
      <div className='table'>
        <div className="app-header row"><Header /></div>

        <div className="app-content row">
          <div className='app-parent-content'>
            <Left ref={prayerLeftRef} />
            <TimeCounter ref={counterRef}
              locationListener={onClickLocation}
              onNewDay={onNewDay}
            />
          </div>
        </div>

        <div className="app-footer row">
          <Footer ref={prayerRef} />
        </div>
      </div>
      <Locations ref={childRef}
        onHide={onHideDialog}
        onLocationChanged={(v) => onChangeLocation(v)}
      />

    </div>
  );
}

export default App;
